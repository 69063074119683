import Vue from "vue";
import {
    Drawer,
    DatePicker,
    TimeSelect,
    Select,
    Option,
    Input,
    Transfer,
    Switch,
    Pagination,
    Popover,
    Table,
    TableColumn,
    Menu,
    MenuItem,
    RadioGroup,
    RadioButton,
    Loading
} from "element-ui";

// 注册组件
Vue.component(Drawer.name, Drawer);
Vue.component(DatePicker.name, DatePicker);
Vue.component(TimeSelect.name, TimeSelect);
Vue.component(Switch.name, Switch);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(Input.name, Input);
Vue.component(Transfer.name, Transfer);
Vue.component(Pagination.name, Pagination);
Vue.component(Popover.name, Popover);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(RadioButton.name, RadioButton);

// 注册指令
Vue.use(Loading.directive);    