import Router from "../../router/index";
import axios from "axios";
import { CacheTool } from "@js/cache-tool.js";
import { Loading } from "element-ui";
import Store from "../../store/index";
import { decrypt, encrypt, IS_ENCEYPT_ENABLE, SHOW_ENCRYPT_LOG } from '@js/aes-tool';

var loading = null;

const httpNew = axios.create({
    baseURL: process.env.VUE_APP_API_URI_NEW
})

const whiteList = ['base/aly/oss', 'sleep_app/v2/web/sleepReport/export', 'v2/flat/ring/xyRingReport/export']

const getEnceyptEnable = (uri) => {
    return IS_ENCEYPT_ENABLE && !whiteList.some(item => uri.includes(item))
}

// 拦截器
// request
httpNew.interceptors.request.use(config => {
    Store.state.loadingShow = true
    config.headers.system = 1
    //正常登录
    const loginInfo = CacheTool.getLoginInfo();
    if (loginInfo) {
        if (config.headers) {
            config.headers.token = loginInfo.token;
            config.headers.uid = loginInfo.uid;
            config.headers.system_id = 0 //web
        } else {
            config.headers = {
                token: loginInfo.token,
                uid: loginInfo.uid,
                system_id: 0 //web
            };
        }
    }
    //平板跳转
    const androidLoginInfo = JSON.parse(localStorage.getItem('androidLoginInfo'))
    if (androidLoginInfo) {
        config.headers = {
            token: androidLoginInfo.token,
            uid: androidLoginInfo.uid,
            system_id: 1, //平板
            system: androidLoginInfo.system,
            platform: 1,
            version_number: androidLoginInfo.version
        };
    }
    //考虑到以后其他的应有都有可能跳转八戒睡眠web 加一个通用用的loginInfo，这个loginInfo必须带system，不然token没法用，Android需要保留兼容老版本。
    const thirdLoginInfo = JSON.parse(localStorage.getItem('thirdLoginInfo'))
    if (thirdLoginInfo) {
        config.headers = {
            token: thirdLoginInfo.token,
            uid: thirdLoginInfo.uid,
            system: thirdLoginInfo.system,
            platform: 1,
            version_number: thirdLoginInfo.version
        };
    }
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    config.headers['X-Encrypt-Enable'] = getEnceyptEnable(config.url)
    return config;
});

// response
httpNew.interceptors.response.use(
    response => {
        // loading.close();
        Store.state.loadingShow = false
        let data = null;
        // 解密
        if (getEnceyptEnable(response.request.responseURL)) {
            // 白名单
            data = JSON.parse(decrypt(response.data))
            if (SHOW_ENCRYPT_LOG) { console.log('🕸️', data) }
        } else {
            data = response.data
        }

        //如果是导出文件，不判断code
        if (data instanceof Blob) {
            return Promise.resolve(response)
        } else {
            const code = data.code;
            switch (code) {
                case "1":
                    return Promise.resolve(data.data);
                case "8":
                case "11":
                    CacheTool.removeLoginInfo();
                    Store.commit("removeAccountInfo");
                    Store.commit("saveIsMainLeftSlim", false);
                    Store.commit("saveSelectedModuleIndex", 0);
                    Store.commit("saveSelectedHospital", { name: "全部", hospitalid: null });
                    Router.push({ path: "/login" });
                    return Promise.reject("授权验证失败，请重新登录");
                default:
                    return Promise.reject(data.msg);
            }
        }
    },
    error => {
        // loading.close();
        Store.state.loadingShow = false
        console.log("error", error);
        if (error.response) {
            return Promise.reject(error.response.data.msg);
        } else {
            return Promise.reject("服务器出错");
        }
    }
);

// 封装方法
async function post(uri, params, options = {}) {
    let newParams = null
    if (getEnceyptEnable(uri)) {
        newParams = encrypt(JSON.stringify(params))
        if (SHOW_ENCRYPT_LOG) { console.log('POST', uri, params) }
    } else {
        newParams = params
    }
    return httpNew.post(uri, newParams, options)
}

async function get(uri, params) {
    let newParams = null
    if (getEnceyptEnable(uri)) {
        const urlSearchParams = new URLSearchParams(filteredParams(params));
        const queryString = urlSearchParams.toString();
        const encData = encrypt(queryString)
        newParams = { encData }
        if (SHOW_ENCRYPT_LOG) { console.log('GET', uri, queryString, newParams) }

        function filteredParams(params) {
            if (!params) { return undefined }
            return Object.entries(params).reduce((acc, [key, value]) => {
                if (value !== undefined && value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});
        }
    } else {
        newParams = params
    }
    return httpNew.get(uri, { params: newParams })
}

async function upload(url, formData, onProgress) {
    return httpNew.post(
        url,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: onProgress, timeout: 1800000 }
    );
}

export { httpNew, post, get, upload }

