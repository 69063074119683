class ValidateTool {
  static isPhoneNumber(number) {
    const mobileReg = /^1[3456789]\d{9}$/;
    return mobileReg.test(number);
  }

  static isStrongPassword(str) {
    const reg1 = /(.*[a-z])/ //至少包含一个小写字母
    const reg2 = /(.*[A-Z])/ //至少包含一个大写字母
    const reg3 = /(.*\d)/ //至少包含一个数字
    const reg4 = /(.*[~!@#$%^&*()_+`\-=\[\]{}|:;"\'<>,.\/?])/ //至少包含一个符号
    const condition1 = reg1.test(str) * 1
    const condition2 = reg2.test(str) * 1
    const condition3 = reg3.test(str) * 1
    const condition4 = reg4.test(str) * 1
    const reg5 = /^.{8,}$/
    const condition5 = reg5.test(str) //大于等于8个字符
    return condition1 + condition2 + condition3 + condition4 >= 3 && condition5
  }

  //身高10 - 250cm
  static isAvailableHeight(value) {
    // 检查输入是否是合法的数字格式
    const regex = /^\d+(\.\d{1})?$/;
    if (!regex.test(value)) {
      return false;
    }
    const num = parseFloat(value);
    return num > 10 && num <= 250;
  };

  //体重0 - 300kg
  static isAvailableWeight(value) {
    // 检查输入是否是合法的数字格式
    const regex = /^\d+(\.\d{1})?$/;
    if (!regex.test(value)) {
      return false;
    }
    const num = parseFloat(value);
    return num > 0 && num <= 300;
  };

  /**
       * @description : 校验身份证号是否合规（18位、15位）
       * @param        {String|Number} value
       * @return       {Boolean} true-合规 false-不合规
       */
  static isAvailableID(value) {
    const psidno = String(value)
    // 1.校验身份证号格式和长度
    const regPsidno = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/
    if (!regPsidno.test(psidno)) {
      return false
    }
    // 2.校验前两位的省份编码是否正确
    const province = { 11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江 ', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北 ', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏 ', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 91: '国外' }
    if (!province[Number(psidno.slice(0, 2))]) {
      return false
    }
    // 3.校验出生日期
    if (psidno.length === 15) {
      // 15位号码 省（2位）市（2位）县（2位）年（2位）月（2位）日（2位）校验码（3位）
      const reg = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/)
      const arrSplit = psidno.match(reg)
      // 15位号码在年份前补 19 或 20
      const year = Number(arrSplit[2].charAt(0)) > 0 ? '19' + arrSplit[2] : '20' + arrSplit[2]
      const month = arrSplit[3]
      const day = arrSplit[4]
      if (!validateBirthday(year, month, day)) {
        return false
      }
    } else if (psidno.length === 18) {
      // 18位号码 省（2位）市（2位）县（2位）年（4位）月（2位）日（2位）校验码（4位）
      const reg = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/)
      const arrSplit = psidno.match(reg)
      const year = arrSplit[2]
      const month = arrSplit[3]
      const day = arrSplit[4]
      if (!validateBirthday(year, month, day)) {
        return false
      }
    } else {
      return false
    }
    // 校验出生日期是否合理
    function validateBirthday(year, month, day) {
      year = Number(year) // 年
      month = Number(month) // 月
      day = Number(day) // 日
      const nowTime = new Date().getTime() // 当前时间戳
      const birthTime = new Date(`${year}-${month}-${day}`).getTime() // 获取出生日期的时间戳
      // 不能是明天出生的吧
      if (birthTime > nowTime) {
        return false
      }
      // 一般人活不到150岁吧
      const nowYear = new Date().getFullYear()
      if ((nowYear - year) > 150) {
        return false
      }
      // 不能是13月出生的吧
      if (month < 1 || month > 12) {
        return false
      }
      // 不能是2月30号、4月31号、5月32号出生的吧
      const date = new Date(year, month, 0) // 获取当月的最后一天
      if (day < 1 || day > date.getDate()) {
        return false
      }
      return true
    }
    // 4.18位号码校验生成的校验码
    if (psidno.length === 18) {
      const Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2] // 加权因子
      const parity = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'] // 校验码
      let sum = 0
      for (let i = 0; i < 17; i++) {
        sum += Number(psidno.charAt(i)) * Wi[i]
      }
      if (parity[sum % 11] !== psidno[17]) {
        return false
      }
    }
    return true
  }

}



export { ValidateTool };
