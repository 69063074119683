import CryptoJS from "crypto-js";

export const IS_ENCEYPT_ENABLE = (process.env.NODE_ENV === 'production');

export const SHOW_ENCRYPT_LOG = false

const API_SECRET_KEY = 'ba_jie_api202412'

export function encrypt(str) {
  if (!str) return '';
  const key = CryptoJS.enc.Utf8.parse(API_SECRET_KEY);
  const encrypted = CryptoJS.AES.encrypt(str, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  const encryptedStr = encrypted.toString();
  return encryptedStr;
}

export function encryptWithBase64(str) {
  if (!str) return '';
  const secret = "baJieSleep_11301";
  var key = CryptoJS.enc.Utf8.parse(secret);
  var encrypted = CryptoJS.AES.encrypt(str, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  var encryptedStr = encrypted.toString();
  var encryptedBase64 = CryptoJS.enc.Base64.parse(encryptedStr);
  var encryptedHex = encryptedBase64.toString(CryptoJS.enc.Hex);
  return encryptedHex;
}

// 解密函数
export function decrypt(ciphertext) {
  const keyBytes = CryptoJS.enc.Utf8.parse(API_SECRET_KEY);
  const decrypted = CryptoJS.AES.decrypt(ciphertext, keyBytes, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}