<template>
    <pop-view @onMaskClick="onMaskClick">
        <div class="change-password-alert">
            <div class="change-password-alert-top">
                <p class="change-password-alert-top-title">修改密码</p>
            </div>
            <div class="change-password-alert-content">
                <change-password-input class="change-password-alert-content-input" title="手机号" v-model="mobile" />
                <div class="change-password-alert-content-code">
                    <change-password-input class="change-password-alert-content-code-input" title="验证码" v-model="code" />
                    <p class="change-password-alert-content-code-button"
                        :class="{ 'change-password-alert-content-code-button-disable': isCountingDown }"
                        @click="onSendCodeClick">
                        {{ codeButtonTitle }}
                    </p>
                </div>
                <change-password-input class="change-password-alert-content-input" title="新密码" v-model="password"
                    :borderColor="borderColor.password" @onBlur="onBlur" />
                <change-password-input class="change-password-alert-content-input" title="重复密码" v-model="passwordAgain"
                    :borderColor="borderColor.passwordAgain" @onBlur="onBlur" />
                <p class="change-password-alert-content-info">*至少包含大写字母、小写字母、数字、符号中的三种及以上，且密码长度需要大于等于8个字符</p>
            </div>
            <div class="change-password-alert-bottom">
                <button v-if="isForce" class="change-password-alert-bottom-cancel" @click="onMaskClick">取消</button>
                <button class="change-password-alert-bottom-confirm" @click="onConfirmClick">确定</button>
            </div>
        </div>
    </pop-view>
</template>
  
<script>
import PopView from "../common/pop-view.vue";
import ChangePasswordInput from "./change-password-input.vue";
import { countDown } from "@js/common-tool.js";
import { CacheTool } from "@js/cache-tool.js";
import { ValidateTool } from "@js/validate-tool.js";
import { encryptWithBase64 } from "@js/aes-tool.js";
export default {
    components: {
        PopView,
        ChangePasswordInput,
    },

    model: {
        prop: "isShow",
        event: "onIsShowChanged"
    },

    props: {
        isShow: Boolean,

    },

    data() {
        return {
            mobile: "",
            code: "",
            password: "",
            passwordAgain: "",
            countDownNumber: 0,
            borderColor: {
                password: "#F6F6F6",
                passwordAgain: "#F6F6F6"
            },
            isForce: true,
        };
    },

    computed: {
        codeButtonTitle() {
            return this.countDownNumber === 0
                ? "获取验证码"
                : this.countDownNumber + "s";
        },

        isCountingDown() {
            return this.countDownNumber != 0;
        },
    },

    mounted() {

        const valid = localStorage.getItem('validPassword') == "true"
        this.isForce = valid
        this.mobile = this.$store.state.accountInfo.userName
    },

    methods: {
        onMaskClick() {

            this.$emit("onIsShowChanged", false)

        },

        onSendCodeClick() {

            if (this.isCountingDown === true) {
                return;
            }
            this.sendVerifyCodeRequest();
        },

        onBlur(inputTitle) {
            switch (inputTitle) {
                case "新密码":
                    if (ValidateTool.isStrongPassword(this.password) == false) {
                        this.borderColor.password = "#F45C50"
                    } else {
                        this.borderColor.password = "#6CC291"
                    }
                    break;
                case "重复密码":
                    if (this.passwordAgain != this.password || ValidateTool.isStrongPassword(this.passwordAgain) == false) {
                        this.borderColor.passwordAgain = "#F45C50"
                    } else {
                        this.borderColor.passwordAgain = "#6CC291"
                    }
                    break;
                default:
                    break;
            }
        },

        onConfirmClick: function () {


            if (this.password != this.passwordAgain) {
                this.$toast.showRed("两次密码输入不一致，请检查！");
                return;
            }
            if (ValidateTool.isStrongPassword(this.password) == false) {
                this.$toast.showRed("请输入8位以上含3种特殊字符的密码！");
                return;
            }
            if (this.code == "" || this.password == "" || this.passwordAgain == "") {
                this.$toast.showRed("内容不能为空");
                return;
            }
            this.changePassword();
        },

        async sendVerifyCodeRequest() {
            const params = {
                "toPhone": this.mobile,
                "smsType": 12 //八戒睡眠修改密码
            };
            try {
                const _ = await this.$api.sendVerifyCode(params);
                this.$toast.loginShowGreen(MSG.verifyCodeSendSucceed);
                this.countDown();
            } catch (error) {
                this.$toast.loginShowRed(error);
            }
        },

        async changePassword() {
            const params = {
                "phone": this.mobile,
                "verificationCode": this.code,
                "newPassword": encryptWithBase64(this.password),
                "duplicatePassword": encryptWithBase64(this.passwordAgain)
            };
            try {
                const _ = await this.$api.changePassword(params);
                this.$toast.showGreen("修改密码成功,请重新登录");
                setTimeout(() => {
                    //关闭弹窗
                    this.$emit("onIsShowChanged", false)
                    //登出
                    this.$emit("changePassword", true)
                }, 1500)

            } catch (error) {
                this.$toast.showRed(error);
            }
        },


        countDown() {
            this.countDownNumber = window.MSG_LIMIT_SECOND;
            countDown({
                remainSeconds: window.MSG_LIMIT_SECOND,
                trigger: (remainSeconds) => {
                    this.countDownNumber = remainSeconds;
                },
                over: () => {
                    this.countDownNumber = 0;
                },
            });
        },
    },
};
</script>
  
<style lang="scss" scoped>
.change-password-alert {
    width: 349px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-top {
        width: 100%;
        height: 60px;
        border: 1px solid rgba($color: #979797, $alpha: 0.16);
        display: flex;
        align-items: center;

        &-title {
            color: rgba($color: #000000, $alpha: 0.9);
            font-size: 16px;
            font-weight: 500;
            margin-left: 27px;
        }
    }

    &-content {
        width: 295px;

        &-input {
            margin-top: 18px;
        }

        &-code {
            display: flex;
            margin-top: 18px;

            &-input {
                width: 195px;
            }

            &-button {
                background-color: #2779FF;
                color: white;
                border-start-start-radius: 0px;
                border-start-end-radius: 6px;
                border-end-start-radius: 0px;
                border-end-end-radius: 6px;
                font-size: 14px;
                width: 100px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;

                &-disable {
                    opacity: 0.65;
                }
            }
        }

        &-info {
            color: #F45C50;
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            margin-top: 18px;
        }
    }

    &-bottom {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 18px;
        margin-bottom: 20px;

        &-cancel {
            color: black;
            background-color: #e8e8e8;
            width: 60px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }

        &-confirm {
            background-color: #2779FF;
            color: white;
            width: 60px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 27px;
        }
    }




}
</style>